<template>
  <div class="mt-2">
    <v-card-text>
      <v-row class="mx-0">
        <v-row>
          <v-col cols="12" sm="12" lg="3" md="3">
            <span class="green--text">Tipología de eventos</span>

            <div class="container-filter">
              <v-icon color="grey">mdi-format-list-bulleted</v-icon>
              <v-autocomplete
                :items="cases_types"
                item-text="name"
                item-value="id"
                single
                label="Tipo de eventos"
                v-model="filters.case_type_id"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" sm="12" lg="3" md="3">
            <span class="green--text">Rango fecha de consulta - Inicio</span>

            <v-menu
              ref="minDatePicker"
              v-model="minDatePicker"
              :close-on-content-click="false"
              :return-value.sync="filters.min_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.min_date"
                  label="Rango de fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="filters.min_date = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.min_date"
                no-title
                scrollable
                :max="filters.max_date"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="minDatePicker = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.minDatePicker.save(filters.min_date)"
                >
                  Aplicar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" lg="3" md="3">
            <span class="green--text">Rango fecha de consulta - Fin</span>

            <v-menu
              ref="maxDatePicker"
              v-model="maxDatePicker"
              :close-on-content-click="false"
              :return-value.sync="filters.max_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.max_date"
                  label="Rango de fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="filters.max_date = ''"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.max_date"
                no-title
                scrollable
                :min="filters.min_date"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="maxDatePicker = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.maxDatePicker.save(filters.max_date)"
                >
                  Aplicar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="12" lg="12" md="12">
          <span class="green--text">Filtrar por</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" lg="3" md="3">
          <div class="container-filter">
            <v-icon color="grey">mdi-map-marker-outline</v-icon>
            <v-autocomplete
              :items="departaments"
              item-text="descripcion"
              item-value="id"
              single
              label="Departamento"
              v-model="filters.departament_id"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" sm="12" lg="3" md="3">
          <div class="container-filter">
            <v-icon color="grey">mdi-map-marker-outline</v-icon>
            <v-autocomplete
              :items="municipalities"
              item-text="descripcion"
              item-value="id"
              single
              label="Municipio"
              v-model="filters.municipality_id"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" sm="12" lg="3" md="3">
          <div class="container-filter">
            <v-icon color="grey">mdi-map-marker-outline</v-icon>
            <v-autocomplete
              :items="guards"
              item-text="descripcion"
              item-value="id"
              single
              label="Resguardo"
              v-model="filters.guard_id"
            ></v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" sm="12" lg="3" md="3">
          <div class="container-filter">
            <v-icon color="grey">mdi-format-list-bulleted</v-icon>
            <v-autocomplete
              :items="states"
              item-text="name"
              item-value="id"
              single
              label="Estado"
              v-model="filters.state_id"
            ></v-autocomplete>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-row class="mx-0">
        <v-row>
          <v-col
            cols="4"
            sm="4"
            lg="4"
            md="4"
            offset-md="4"
            class="d-flex flex-column justify-center"
          >
            <div class="d-flex justify-center" style="">
              <v-btn
                rounded
                color="primary"
                dark
                class="mb-5"
                @click="generateReports()"
              >
                GENERAR REPORTE <v-icon>mdi-chart-line</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>

    <v-card-text>
      <div class="d-flex align-center justify-md-space-between">
        <h3 class="title blue-grey--text text--darken-2 font-weight-regular">
          LISTADO DE CASOS
        </h3>
        <v-btn
          rounded
          color="primary"
          dark
          class="mb-5"
          @click="donwloadListCase()"
        >
          EXPORTAR A EXCEL <v-icon>mdi-chart-line</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <v-data-table
      v-if="headers.length"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        'items-per-page-options': [5,10,15]

      }"
      :headers="headers"
      :items="dataTable"
      :items-per-page="15"
      :search="search"
      item-key="id"
    ></v-data-table>
  </div>
</template>

<script>
import sweetalert from "sweetalert2";
import {
  listCases,
  listCasesDonwload,
  parameterization,
} from "../../../../services/graphicReports/ordinaryJusticeServices";

export default {
  props: ["queryStats"],
  data() {
    return {
      cases_types: [],
      departaments: [],
      municipalities: [],
      states: [],
      guards: [],
      dataTable: [],
      search: "",
      headers: [
        {
          text: "ID.",
          align: "start",
          sortable: true,
          value: "case_id",
        },
        { text: "FECHA RECEP.", value: "case_date" },
        { text: "TIPO DE CASO", value: "casetype_name" },
        { text: "DEPTO. RECEP.", value: "departament_description" },
        { text: "MUNICIPIO RECEP.", value: "municipality_description" },
        { text: "RESGUARDO RECEP.", value: "territory_description" },
        { text: "DESCRIPCIÓN CASO", value: "case_description" },
        { text: "ENCARGADO", value: "user_name" },
        { text: "ESTADO", value: "casestate_name" },
      ],
      filters: {
        case_type_id: 0,
        departament_id: 0,
        municipality_id: 0,
        guard_id: 0,
        state_id: 0,
        min_date: "",
        max_date: "",
      },
      minDatePicker: false,
      maxDatePicker: false,
    };
  },
  methods: {
    async fillData() {
      this.showLoader();
      const { data } = await parameterization(this.queryStats);
      this.cases_types = data.cases_types || [];
      this.municipalities = data.municipalities || [];
      this.departaments = data.departaments || [];
      this.guards = data.guards || [];
      this.states = data.states || [];

      this.hideLoader();
    },
    async generateReports() {
      this.showLoader();
      const { data = [] } = await listCases(this.filters);
      this.dataTable = data;
      this.hideLoader();
    },
    async donwloadListCase() {
      const { data = null } = await listCasesDonwload(this.filters);
      if (!data) return;

      var url = window.URL || window.webkitURL;
      const link = url.createObjectURL(data);
      var a = document.createElement("a");
      a.setAttribute("download", "Lista de casos - Justicia Ordinaria.xlsx");
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  async created() {
    try {
      await this.fillData();
    } catch (err) {
      this.hideLoader();
      sweetalert.fire(
        "Ups!",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped>
.container-filter {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
