import baseService from "../baseService";
const resource = "/rest/v1";

export function CasesByTerritory(data) {
  return baseService.post(
    `${resource}/graphic_reports/ordinary-justice/cases-by-territory`,
    data
  );
}

export function CasesByState(data) {
  return baseService.post(
    `${resource}/graphic_reports/ordinary-justice/cases-by-state`,
    data
  );
}

export function casesByMunicipalitiesMaps(data) {
  return baseService.post(
    `${resource}/graphic_reports/ordinary-justice/cases-by-municipalities-maps`,
    data
  );
}

export function listCases(data) {
  return baseService.post(
    `${resource}/graphic_reports/ordinary-justice/list-cases`,
    data
  );
}

export async function listCasesDonwload(data) {
  return baseService.post(
    `${resource}/graphic_reports/ordinary-justice/list-cases-download`,
    data,
    {
      responseType: "blob",
    }
  );
}

export function parameterization() {
  return baseService.get(`${resource}/graphic_reports/ordinary-justice/parameterization`);
}
