<template>
  <div class="mt-5" style="height: 500px; width: 100%">
    <l-map
      v-if="Object.keys(center).length"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      style="height: 100%"
    >
      <l-tile-layer :url="url" />

      <l-marker
        v-for="(report, index) in reportData"
        :lat-lng="report.withTooltip"
        v-bind:key="index"
      >
        <l-tooltip :options="{ permanent: true, interactive: true }">
          <div>
            {{ report.descripcion || "" }}
          </div>
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { casesByMunicipalitiesMaps } from "../../../../services/graphicReports/ordinaryJusticeServices";
import { Icon } from "leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  props: ["queryStats"],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return {
      reportData: [],
      zoom: 8.5,
      center: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      mapOptions: {
        zoomSnap: 0.5,
      },
    };
  },
  methods: {
    async loadReportData() {
      const { data = [] } = await casesByMunicipalitiesMaps(this.queryStats);
      this.center = [];
      this.reportData = data
        .filter(
          (item) => item.municipality__latitude && item.municipality__longitude
        )
        .map((item) => {
          const lat = parseFloat(item.municipality__latitude.replace(",", "."));
          const long = parseFloat(
            item.municipality__longitude.replace(",", ".")
          );
          const _latLng = latLng(lat, long);
          return {
            descripcion: `${item.municipality__descripcion || ""} - ${
              item.total || 0
            } - ${
              (item.total || 0) > 1 || (item.total || 0) === 0
                ? "Casos"
                : "Caso"
            }`,
            withTooltip: _latLng,
            total: item.total,
          };
        });
      const maxTotalMunicipalities = this.reportData.find(
        (report) =>
          report.total === Math.max(...this.reportData.map((o) => o.total))
      );
      this.center =
        (maxTotalMunicipalities && maxTotalMunicipalities.withTooltip) ||
        latLng(4.570868, -74.297333);
    },
  },
  async beforeMount() {
    await this.loadReportData();
  },
};
</script>
