<template>
    <v-container fluid>
      <template>
        <v-breadcrumbs>
          <template>
            <v-breadcrumbs-item>
              <h3>REPORTES</h3>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item>
              <v-icon color="primary">mdi-home</v-icon>
            </v-breadcrumbs-item>
            <v-breadcrumbs-item> Reportes </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </template>
  
      <template>
        <v-card class="overflow-hidden mt-2">
          <v-toolbar flat color="white">
            <v-toolbar-title class="font-weight-bold" color="grey darken-3">
              REPORTE GRÁFICO
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
  
          <v-card-text>
            <v-row class="mx-0">
              <v-row>
                <v-col cols="12" sm="12" lg="3" md="3">
                  <span class="green--text">Tipología de consulta</span>
  
                  <div class="container-filter">
                    <v-icon color="grey">mdi-format-list-bulleted</v-icon>
                    <v-autocomplete
                      :items="cases_types"
                      item-text="name"
                      item-value="id"
                      single
                      label="Tipo de casos"
                      v-model="filters.case_type_id"
                      @change="changeCasesTypes"
                    ></v-autocomplete>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" lg="3" md="3">
                  <span class="green--text"
                    >Rango fecha de consulta - Inicio</span
                  >
  
                  <v-menu
                    ref="minDatePicker"
                    v-model="minDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filters.min_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.min_date"
                        label="Rango de fecha"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="handleClearMinDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.min_date"
                      no-title
                      scrollable
                      :max="filters.max_date"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="minDatePicker = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="handleSetMinDate">
                        Aplicar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" lg="3" md="3">
                  <span class="green--text">Rango fecha de consulta - Fin</span>
  
                  <v-menu
                    ref="maxDatePicker"
                    v-model="maxDatePicker"
                    :close-on-content-click="false"
                    :return-value.sync="filters.max_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filters.max_date"
                        label="Rango de fecha"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="handleClearMaxDate"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filters.max_date"
                      no-title
                      scrollable
                      :min="filters.min_date"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="maxDatePicker = false">
                        Cancelar
                      </v-btn>
                      <v-btn text color="primary" @click="handleSetMaxDate">
                        Aplicar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
  
          <template v-if="currentCaseType && (currentCaseType.id || currentCaseType.id === 0)">
            <v-card-text>
              <v-row class="mt-2">
                <v-col md="6" lg="6" sm="12" xs="12">
                  <v-card>
                    <v-card-text class="">
                      <div class="d-flex align-center justify-lg-space-between">
                        <h3
                          class="title blue-grey--text text--darken-2 font-weight-regular"
                        >
                          {{ currentCaseType.name }}  POR TERRITORIOS
                        </h3>

                        <div class="container-filter">
                          <v-icon color="grey">mdi-map-marker-outline</v-icon>
                        <v-autocomplete
                          :items="[
                            {id: 0, descripcion: 'TODOS'},
                            ...territories
                          ]"
                          item-text="descripcion"
                          item-value="id"
                          single
                          label="Territorios"
                          v-model="filters.case_territory_id"
                          @change="changeSelectCasesTerritory"
                          ></v-autocomplete>
                        </div>
                      </div>
                      <h6 class="subtitle-2 font-weight-light"></h6>
                      <div v-if="reloadCasesTerritory" class="">
                        <cases-by-territory
                          :queryStats="filters"
                        ></cases-by-territory>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="6" lg="6" sm="12" xs="12">
                  <v-card>
                    <v-card-text class="">
                      <h3
                        class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                      >
                        {{ currentCaseType.name }} POR ESTADO DEL PROCESO
                      </h3>
                      <h6 class="subtitle-2 font-weight-light"></h6>
                      <div class="" max-height="100">
                        <cases-by-state
                          :queryStats="filters"
                        ></cases-by-state>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
  
              <v-row class="mt-2">
                <v-col md="12" lg="12" sm="12" xs="12">
                  <v-card>
                    <v-card-text class="">
                      <h3
                        class="title blue-grey--text text--darken-2 font-weight-regular text-uppercase"
                      >
                        {{ currentCaseType.name }} POR MUNICIPIO
                      </h3>
                      <h6 class="subtitle-2 font-weight-light"></h6>
                      <div class="" max-height="100">
                        <cases-by-municipalities-maps
                          :queryStats="filters"
                        ></cases-by-municipalities-maps>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </template>
        </v-card>
  
        <v-card class="overflow-hidden mt-2">
          <v-toolbar flat color="white">
            <v-toolbar-title class="font-weight-bold" color="grey darken-3">
              GENERAR REPORTE EXCEL
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="mt-2">
              <v-col md="12" lg="12" sm="12" xs="12">
                <v-card>
                  <v-card-text class="">
                    <div class="" max-height="100">
                      <list-cases :queryStats="filters"></list-cases>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
  </template>
  
  <script>
  import sweetalert from "sweetalert2";
  
  import CasesByTerritory from "./reports/CasesByTerritory.vue";
  import CasesByState from "./reports/CasesByState.vue";
  import CasesByMunicipalitiesMaps from './reports/CasesByMunicipalitiesMaps.vue'
  import ListCases from "./reports/ListCases.vue";
  
  import { parameterization } from "../../../services/graphicReports/ordinaryJusticeServices";
  
  export default {
    components: { CasesByState, CasesByTerritory, CasesByMunicipalitiesMaps, ListCases },
    name: "graphicReports.vue",
    data() {
      return {
        currentCaseType: {},
        cases_types: [],
        territories: [],
        filters: {
          min_date: "",
          max_date: "",
          case_type_id: 0,
          case_territory_id: 0,
        },
        minDatePicker: false,
        maxDatePicker: false,
        reloadCasesTerritory: true
      };
    },
    methods: {
      async fillData() {
        this.showLoader();
        const { data } = await parameterization();
        this.cases_types = data.cases_types || [];
        this.territories = data.territories || [];
        this.currentCaseType = this.cases_types[0] || {};
        this.hideLoader();
      },
      changeCasesTypes(event) {
        this.currentCaseType = this.cases_types.find(
          (item) => item.id === event
        );
        const auxCurrentCaseType = this.currentCaseType;
        this.currentCaseType = {};
  
        this.$nextTick(() => {
          this.currentCaseType = auxCurrentCaseType;
        });
      },
      handleClearMinDate() {
        this.filters.min_date = '';
        this.handleReloadReports(); 
      },
      handleClearMaxDate() {
        this.filters.max_date = '';
        this.handleReloadReports(); 
      },
      handleSetMinDate() {
        this.$refs.minDatePicker.save(this.filters.min_date);
        this.handleReloadReports();
      },
      handleSetMaxDate() {
        this.$refs.maxDatePicker.save(this.filters.max_date);
        this.handleReloadReports();      
      },
      handleReloadReports() {
        const auxCurrentCaseType = this.currentCaseType;
        this.currentCaseType = {};
  
        this.$nextTick(() => {
          this.currentCaseType = auxCurrentCaseType;
        });
      },
      async changeSelectCasesTerritory() {
        this.reloadCasesTerritory = false;

        this.$nextTick(() => {
            this.reloadCasesTerritory = true;
        })
      },
    },
    async created() {
      try {
        await this.fillData();
      } catch (err) {
        this.hideLoader();
        sweetalert.fire(
          "Ups!",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
  };
</script>

<style scoped>
  .container-filter {
    display: flex;
    align-items: center;
    gap: 5px;
  }
</style>
